const platforms = {
  question: 'قم باختيار النظام الذي ترغب بالبرمجة عليه',
  answers: [
    'نظام الآندرويد',
    'نظام الآيفون iOS',
    'نظام الآندرويد والآيفون iOS',
    'تطبيقات الويب Web Apps',
    'جميع الأنظمة',
  ],
  tracks: [[1], [1002], [1, 1002], [1001], [1, 1001, 1002]],
};

const proPlatforms = {
  question: 'قم باختيار النظام الذي ترغب بالبرمجة عليه',
  answers: [
    'نظام الآندرويد',
    'نظام الآيفون iOS',
    'نظام الآندرويد والآيفون iOS',
    'تطبيقات الويب Web Apps',
    'جميع الأنظمة',
  ],
  tracks: [[1, 1001], [1001, 1002], [1, 1001, 1002], [1001], [1, 1001, 1002]],
};

const levels = {
  question: 'يرجى اختيار المستوى الخاص بك في لغات البرمجة',
  answers: [
    'لم أقم بالبرمجة من قبل',
    'قمت بالبرمجة قليلاً',
    'أستطيع البرمجة بسهولة باستخدام لغة أو أكثر',
  ],
  tracks: [[1049, 1017], [1049, 1017], []],
};

export default {
  question: 'ماهو سبب رغبتك بتعلم البرمجة؟',
  answers: [
    {
      answer: 'أرغب في العمل كمبرمج محترف',
      next: {
        question: 'ترغب بالعمل كأي نوع من أنواع المبرمجين؟',
        answers: [
          {
            answer:
              'مبرمج للواجهات Frontend (أي كل ما يشاهده ويتفاعل معه المستخدم)',
            next: {
              ...platforms,
              next: levels,
            },
          },
          {
            answer: 'مبرمج Backend (أي البنية التحتية وقواعد البيانات)',
            next: levels,
          },
          {
            answer: 'مبرمج Full-stack (أي Frontend مع Backend)',
            next: {
              ...proPlatforms,
              next: levels,
            },
          },
          'لست متأكداً',
        ],
        tracks: [[], [1001], [], [1049, 1]],
      },
    },
    {
      answer: 'أنا مبرمج وأرغب في تطوير قدراتي البرمجية',
      next: {
        question: 'ماهي مهارات البرمجة التي ترغب في تطويرها؟',
        answers: [
          {
            answer: 'تطبيقات الويب والمواقع والأجهزة الذكية',
            next: {
              question: 'ما الذي ترغب في تعلمه؟',
              answers: [
                {
                  answer:
                    'برمجة الواجهات Frontend (أي كل ما يشاهده ويتفاعل معه المستخدم)',
                  next: platforms,
                },
                'برمجة الـ Backend (أي البنية التحتية وقواعد البيانات)',
                {
                  answer: 'برمجة الـ Full-stack (أي Frontend مع Backend)',
                  next: proPlatforms,
                },
              ],
              tracks: [[], [1001], []],
            },
          },
          {
            answer: 'أرغب في تعلم لغة برمجة معينة',
            next: {
              question: 'ماهي لغة البرمجة التي ترغب في تعلمها؟',
              answers: [
                'جافا Java',
                'HTML/CSS',
                'لغة البرمجة C',
                'لغة البرمجة ++C',
                'لست متأكداً',
              ],
              tracks: [[1016], [1049], [1015], [1011], [1016]],
            },
          },
        ],
      },
    },
    {
      answer: 'أرغب في بناء مشروع أو فكرة أملكها',
      next: {
        question: 'ما هو نوع المشروع الذي ترغب في بنائه؟',
        answers: [
          {
            answer: 'تطبيق للأجهزة الذكية',
            next: {
              question: 'قم باختيار النظام الذي يناسبك',
              answers: ['نظام الآندرويد', 'نظام الآيفون iOS', 'الإثنان معاً'],
              next: levels,
              tracks: [[1], [1002], [1, 1002]],
            },
          },
          {
            answer: 'موقع أو منصة إلكترونية',
            next: levels,
          },
          {
            answer: 'الإثنان معاً',
            next: {
              question:
                'يرجى إختيار نظام الأجهزة الذكية الذي ترغب في البرمجة عليه',
              answers: ['نظام الآندرويد', 'نظام الآيفون iOS', 'الإثنان معاً'],
              next: levels,
              tracks: [
                [1, 1001],
                [1001, 1002],
                [1, 1001, 1002],
              ],
            },
          },
        ],
        tracks: [[], [1001], []],
      },
    },
    {
      answer: 'أرغب في التعلم من أجل الجامعة',
      next: {
        question: 'ماهي لغات البرمجة التي تحتاج المساعدة لتعلمها؟',
        answers: ['جافا Java', 'HTML/CSS', 'لغة البرمجة C', 'لغة البرمجة ++C'],
        tracks: [[1016], [1049], [1015], [1011]],
      },
    },
    {
      answer: 'كهواية أو مهارة إضافية',
      next: {
        question: 'ماهو سبب إهتمامك بتعلم البرمجة؟',
        answers: [
          {
            answer: 'لتطوير تطبيقات الأجهزة الذكية',
            next: {
              question: 'يرجى اختيار النظام الذي يناسبك',
              answers: [
                {
                  answer: 'نظام الآندرويد',
                  next: levels,
                },
                {
                  answer: 'نظام الآيفون iOS',
                  next: levels,
                },
                {
                  answer: 'الإثنان معاً',
                  next: levels,
                },
              ],
              tracks: [[1], [1002], [1, 1002]],
            },
          },
          {
            answer: 'تطوير تطبيقات الويب والمواقع',
            next: levels,
          },
          {
            answer: 'أرغب في تعلم البرمجة بشكل عام',
            next: levels,
          },
        ],
        tracks: [[], [1001], [1]],
      },
    },
    'لست متأكداً',
  ],
  tracks: [[], [], [], [], [], [1049, 1]],
};
