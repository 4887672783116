import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {
  compose,
  branch,
  withStateHandlers,
  withPropsOnChange,
  withProps,
  renderNothing,
} from 'recompose';
import {updateUserProfile} from 'modules/user/actions/profile';
import Question from './Question';

const Survey = ({question, onValidate}) => {
  return (
    <Question key={question.question} {...question} onValidate={onValidate} />
  );
};

Survey.propTypes = {
  question: PropTypes.shape({
    question: PropTypes.string.isRequired,
    answers: PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
          answer: PropTypes.string,
          next: PropTypes.object,
        }),
      ]),
    ).isRequired,
    tracks: PropTypes.array,
    next: PropTypes.object,
  }),
  path: PropTypes.array.isRequired,
  onValidate: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  dUpdateUserProfile: updateUserProfile,
};

const enhance = compose(
  connect(null, mapDispatchToProps),

  withProps(() => ({
    question: null,
  })),

  withStateHandlers(
    {path: [], selectedTracks: []},
    {
      onValidate: ({path, selectedTracks}, {questions}) => (index) => {
        try {
          let currentQuestion = questions;

          path.forEach((currentPath) => {
            currentQuestion =
              currentQuestion.next || currentQuestion.answers[currentPath].next;
          });

          const currentAnswerTracks = currentQuestion.tracks
            ? currentQuestion.tracks[index]
            : [];

          if (window && window.mixpanel) {
            window.mixpanel.track('Onboarding current step', {
              onboarding_current_step: currentQuestion.question,
            });
          }

          if (typeof window !== 'undefined') {
            if (window.dataLayer) {
              window.dataLayer.push({
                onboardingCurrentStep: currentQuestion.question,
                event: 'Onboarding current step',
              });
            }
          }

          return {
            path: [...path, index],
            selectedTracks: [...selectedTracks, ...currentAnswerTracks],
          };
        } catch (e) {
          console.error('Cannot get selected tracks IDs', {
            selectedTracks,
            path,
            index,
          });
          return {
            path: [...path, index],
          };
        }
      },
    },
  ),

  withPropsOnChange(
    ({path, selectedTracks}, nextProps) => `${path}` !== `${nextProps.path}`,
    ({questions, path, selectedTracks, submit, dUpdateUserProfile}) => {
      let question = {...questions};

      path.forEach((stepIndex) => {
        if (typeof question.answers === 'undefined') {
          question = question[stepIndex];
        } else if (
          question.answers &&
          question.answers.length > stepIndex &&
          typeof question.answers[stepIndex].next !== 'undefined'
        ) {
          question = question.answers[stepIndex].next;
        } else if (typeof question.next !== 'undefined') {
          question = question.next;
        } else {
          submit(path);
          dUpdateUserProfile(
            {
              selected_tracks: JSON.stringify(selectedTracks),
            },
            {hasNotif: false},
          );
          if (window && window.mixpanel) {
            window.mixpanel.track('Onboarding submit selected tracks', {
              onboarding_submit_selected_tracks: true,
            });
          }

          if (typeof window !== 'undefined') {
            if (window.dataLayer) {
              window.dataLayer.push({
                event: 'Onboarding submit selected tracks',
              });
            }
          }
        }
      });

      return {
        question,
      };
    },
  ),

  branch(({question}) => !question, renderNothing),
);

export default enhance(Survey);
