import {Router} from '@reach/router';
import redirectIfAnonymous from 'core/libs/hoc/redirectIfAnonymous';
import redirectIfPartner from 'core/libs/hoc/redirectIfPartner';
import React from 'react';
import {compose} from 'recompose';
import {NotFound} from 'theme/pages';
import OnboardingSurvey from './containers/OnboardingSurvey';
import SuggestedTrack from './containers/SuggestedTrack';
import UserFullName from './containers/UserFullName';

const OnBoarding = () => (
  <Router>
    <UserFullName path="/start" />
    <OnboardingSurvey path="/start/choose-objective" />
    <SuggestedTrack path="/start/suggested-track" />
    <NotFound path="/start/*" />
  </Router>
);

const enhance = compose(redirectIfAnonymous, redirectIfPartner);

export default enhance(OnBoarding);
