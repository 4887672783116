import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {compose, lifecycle, setPropTypes} from 'recompose';
import {startTrackTime, stopTrackTime} from './actions';

const TrackTime = () => null;

const enhance = compose(
  connect(),
  setPropTypes({
    message: PropTypes.string,
    messages: PropTypes.array,
  }),
  lifecycle({
    componentDidMount() {
      const {message, messages, dispatch} = this.props;
      dispatch(startTrackTime(message ? [message] : messages));
    },
    componentWillUnmount() {
      const {dispatch} = this.props;
      dispatch(stopTrackTime());
    },
  }),
);

export default enhance(TrackTime);
