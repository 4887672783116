/* eslint-disable jsx-a11y/alt-text */
import {Link} from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import NewDesignButton from 'modules/common/NewDesignButton';
import {FormattedMessage} from 'react-intl';
import Thumbnail from 'modules/common/Thumbnail';
import BarmejLogo from 'theme/assets/images/barmej-white-logo.svg';
import IntroToCodingImage from 'theme/assets/images/intro-to-coding.png';
import {
  midCentered,
  rightScreen,
  fullScreen,
  customButton,
} from '../styles.scss';

const SideScreen = ({icon, description, bgColor, isUserFullNamePage}) => (
  <div
    className={
      isUserFullNamePage
        ? `${fullScreen} ph4 w-100 fr`
        : `${rightScreen} ph3 w-50-ns w-100-m w-100 fr`
    }
    style={{backgroundColor: bgColor}}
  >
    <div className="tr-ns tc-m tc pt4 pr4-ns pr0-m pr0 db-ns dn-m dn">
      <Link to="/dashboard">
        <img src={BarmejLogo} height="50" />
      </Link>
    </div>
    <div className={`tc relative ${midCentered}`}>
      <img src={icon} className="mb4-ns mb2-m mb1" alt="icon" />
      <h3 className="f4-ns f7 ph4-ns ph2-m ph0 white bold lh-copy">
        <FormattedMessage id={description} />
      </h3>
      {isUserFullNamePage && (
        <div>
          <div className="f4-ns f7">
            <NewDesignButton
              className={`${customButton} ma4-ns ma2-m ma1`}
              link="/start/choose-objective"
            >
              <FormattedMessage id="guide_me_to_right_track" />
            </NewDesignButton>
            <NewDesignButton
              className={`${customButton} ma4-ns ma2-m ma1`}
              link="/tracks"
            >
              <FormattedMessage id="browse_free_tracks" />
            </NewDesignButton>
          </div>
          <div>
            <Thumbnail
              title="اﺑﺪأ ﺑﻤﻘﺪﻣﺔ ﻋﺎﻟﻢ اﻟﺒﺮﻣﺠﺔ اﻟﺂن"
              bgColor="rgba(0, 71, 255, 0.4)"
              bgImg={IntroToCodingImage}
              link="/مقدمة-في-عالم-البرمجة/مقدمة-في-عالم-البرمجة/نتائج-البرمجة/تطبيقات-يمكن-فعلها-بالبرمجة"
              showTrackLogo
            />
          </div>
        </div>
      )}
    </div>
  </div>
);

SideScreen.propTypes = {
  icon: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  bgColor: PropTypes.string.isRequired,
  isUserFullNamePage: PropTypes.bool,
};

export default SideScreen;
