import decodeId from 'core/libs/helpers/decodeId';
import log from 'core/libs/logger';
import {useQuery} from '@apollo/client';
import TrackTime from 'modules/common/TrackTime';
import PropTypes from 'prop-types';
import React from 'react';
import {Helmet} from 'react-helmet';
import {FormattedMessage, useIntl} from 'react-intl';
import {graphql} from '@apollo/client/react/hoc';
import gql from 'graphql-tag.macro';
import {connect} from 'react-redux';
import {updateUserProfile} from 'modules/user/actions/profile';
import {compose, withPropsOnChange} from 'recompose';
import ObjectiveIllustration from 'theme/assets/images/onboarding-objective.svg';
import Thumbnail from 'modules/common/Thumbnail';
import SideScreen from '../components/SideScreen';
import ALL_TRACKS from '../graphql/tracks.graphql';
import {suggestedTrackContainer, suggestedTrackContent} from '../styles.scss';

const {PARTNER} = process.env;

const TRACK_STEP = gql`
  query trackStep($trackId: ID!) {
    track(id: $trackId) {
      id
      slug
      trackstepSet {
        edges {
          node {
            id
            position
            course {
              id
              slug
              stageSet {
                edges {
                  node {
                    id
                    position
                    slug
                    stepSet {
                      edges {
                        node {
                          id
                          slug
                          position
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const SuggestedTrack = (props) => {
  const intl = useIntl();
  const {formatMessage} = intl;
  const {recommendedTracks, authenticated} = props;
  const firstRecommendedTrackId = recommendedTracks && recommendedTracks[0].id;
  const firstRecommendedTrackName =
    recommendedTracks && recommendedTracks[0].name;
  const firstRecommendedTrackBgImg =
    recommendedTracks && recommendedTracks[0].backgroundImage.url;

  const {data} = useQuery(TRACK_STEP, {
    variables: {trackId: btoa(`TrackType:${firstRecommendedTrackId}`)},
    skip: !authenticated || !firstRecommendedTrackId,
  });

  const trackSlug = data && data.track && data.track.slug;
  const firstCourse =
    data &&
    data.track &&
    data.track.trackstepSet &&
    data.track.trackstepSet.edges.filter(
      ({node: {position}}) => position === 0,
    );
  const firstCourseSlug = firstCourse && firstCourse[0].node.course.slug;

  const firstStage =
    firstCourse &&
    firstCourse[0].node.course.stageSet.edges.filter(
      ({node: {position}}) => position === 0,
    );
  const firstStageSlug = firstStage && firstStage[0].node.slug;

  const firstStep =
    firstStage &&
    firstStage[0].node.stepSet.edges.filter(
      ({node: {position}}) => position === 0,
    );
  const firstStepSlug = firstStep && firstStep[0].node.slug;

  return (
    <div className="bg-white tc cf">
      <Helmet title={formatMessage({id: 'helmet_post_registration'})} />
      <TrackTime message="Track time of refer friends" />
      <SideScreen
        icon={ObjectiveIllustration}
        description="survey_description"
        bgColor="#936cec"
      />
      <div className="w-50-ns w-100-m w-100 fl">
        <div className={`${suggestedTrackContainer} ph4 pv2`}>
          <div className={`${suggestedTrackContent} center`}>
            <h4 className="f5 tc mb4">
              <FormattedMessage id="start_your_suggested_track" />
            </h4>
            <div>
              {trackSlug &&
                firstCourseSlug &&
                firstStageSlug &&
                firstStepSlug && (
                  <Thumbnail
                    title={firstRecommendedTrackName}
                    bgColor="rgba(93, 194, 142, 1)"
                    bgImg={firstRecommendedTrackBgImg}
                    link='/subscription'
                    state = {{ fromLink: `/${trackSlug}/${firstCourseSlug}/${firstStageSlug}/${firstStepSlug}` }}
                  />
                )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

SuggestedTrack.propTypes = {
  recommendedTracks: PropTypes.array,
  authenticated: PropTypes.bool,
};

const enhance = compose(
  connect(
    ({user, auth}) => ({
      user: user.profile.data,
      authenticated: auth.authenticated,
    }),
    (dispatch) => ({
      hideRecommendedTracksBox: () => {
        dispatch(
          updateUserProfile({hide_recommended_tracks: true}, {hasNotif: false}),
        );
      },
    }),
  ),
  graphql(ALL_TRACKS, {
    options: () => ({
      variables: {partner: PARTNER},
    }),
    skip: ({authenticated}) => !authenticated,
    props: ({data}) => {
      const {error, loading, allTracks} = data;
      if (loading) return {loading};
      if (error) return {error, loading: false};
      const publishedTracks =
        allTracks &&
        allTracks.edges &&
        allTracks.edges
          .filter(({node: track}) => !track.soon)
          .map(({node: track}) => ({
            ...track,
            id: decodeId(track.id),
          }));

      return {
        publishedTracks,
      };
    },
  }),
  withPropsOnChange(['publishedTracks', 'user'], ({publishedTracks, user}) => {
    let selectedTracks;
    try {
      if (user.selected_tracks === '[-1]') {
        selectedTracks = [1049, 1];
      } else {
        selectedTracks = JSON.parse(user.selected_tracks);
      }
    } catch (e) {
      console.error('Cannot parse selected tracks', {
        selectedTracks: user.selected_tracks,
      });
    }
    if (!Array.isArray(selectedTracks) || selectedTracks.length === 0)
      return {};
    return {
      recommendedTracks:
        publishedTracks &&
        selectedTracks
          .map((selectedTrack) =>
            publishedTracks.find(({id}) => id === selectedTrack),
          )
          .sort((track1, track2) =>
            track1.position > track2.position ? 1 : -1,
          )
          .filter((track, index) => {
            if (track === undefined || track.id === undefined) {
              log('We have wrong id in selected tracks !', {
                index,
                selectedTracks,
                track,
                currentUrl:
                  typeof window !== 'undefined' && window.location.href,
              });
              return false;
            }
            return true;
          }),
    };
  }),
);

export default enhance(SuggestedTrack);
