import {Redirect} from '@reach/router';
import React from 'react';
import {branch, compose, renderComponent} from 'recompose';

const {PARTNER} = process.env;

const RedirectIfPartner = () => <Redirect noThrow to="/404" />;

export default compose(
  branch(() => PARTNER !== 'BARMEJ', renderComponent(RedirectIfPartner)),
);
