import questions from 'data/onboardingSurvey';
import {navigate} from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import {Helmet} from 'react-helmet';
import {useIntl} from 'react-intl';
import {connect} from 'react-redux';
import {compose, withHandlers} from 'recompose';
import ObjectiveIllustration from 'theme/assets/images/onboarding-objective.svg';
import saveOnboardingSurvey from '../actions';
import OnBoardingHero from '../components/OnBoardingHero';
import SideScreen from '../components/SideScreen';
import Survey from '../components/Survey';

const OnboardingSurvey = (props) => {
  const intl = useIntl();
  const {formatMessage} = intl;
  const {submit} = props;
  return (
    <div className="bg-white tc cf">
      <Helmet title={formatMessage({id: 'helmet_post_registration'})} />
      <SideScreen
        icon={ObjectiveIllustration}
        description="survey_description"
        bgColor="#936cec"
      />
      <div className="w-50-ns w-100-m w-100 fl ph2-ns ph0-m ph0">
        <OnBoardingHero title="survey_title" step={1} />
        <div className="ph4 pv2">
          <Survey questions={questions} submit={submit} />
        </div>
      </div>
    </div>
  );
};

OnboardingSurvey.propTypes = {
  submit: PropTypes.func.isRequired,
};

const enhance = compose(
  connect(),
  withHandlers({
    submit: ({dispatch}) => (answers) => {
      dispatch(
        saveOnboardingSurvey(answers, () => {
          navigate('/start/suggested-track');
        }),
      );
    },
  }),
);

export default enhance(OnboardingSurvey);
