const IDLE_TIMEOUT_TIME = 10000;

const EVENTS = ['mousemove', 'keypress', 'onscroll', 'onwheel', 'videoplaying'];

let timeout;
let startTime;
let messages;
let runResetTimeOut;

const sendTime = () => {
  const currentTime = new Date();
  const elapsedMinutes =
    Math.round(((currentTime - startTime) / 1000 / 60) * 100) / 100;
  startTime = undefined;
  if (process.env.NODE_ENV === 'production' && elapsedMinutes > 0) {
    messages.forEach((message) => {
      if (window.mixpanel) {
        window.mixpanel.people.increment(message, elapsedMinutes);
      }

      if (typeof window !== 'undefined') {
        if (window.dataLayer) {
          window.dataLayer.push({
            elapsedMinutes,
            message,
            event: 'Track Time',
          });
        }
      }
    });
  }
  return {type: 'SEND_TRACK_TIME', messages, elapsedMinutes};
};

const resetTimeout = (timeoutCallback) => {
  if (!startTime) startTime = new Date();
  if (timeout) clearTimeout(timeout);
  timeout = setTimeout(timeoutCallback, IDLE_TIMEOUT_TIME);
  // console.log('RESET TIMEOUT');
};

export const startTrackTime = (_messages) => (dispatch) => {
  dispatch({type: 'START_TRACK_TIME', _messages});
  messages = _messages;
  const timeoutCallback = () => dispatch(sendTime());
  runResetTimeOut = () => resetTimeout(timeoutCallback);
  if (document) {
    EVENTS.forEach((event) => {
      document.addEventListener(event, runResetTimeOut);
    });
  }
  runResetTimeOut();
};

export const stopTrackTime = () => (dispatch) => {
  if (timeout) clearTimeout(timeout);
  if (startTime) dispatch(sendTime());

  if (document) {
    EVENTS.forEach((event) => {
      document.removeEventListener(event, runResetTimeOut);
    });
  }
  runResetTimeOut = undefined;
  dispatch({type: 'STOP_TRACK_TIME'});
};
