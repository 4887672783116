/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'gatsby';
import CodeLogo from 'theme/assets/images/code.svg';
import PlayBtnLogo from 'theme/assets/images/play-white.svg';
import {
  container,
  videoTutorial,
  playBtn,
  trackTitle,
  trackLogo,
} from './styles.scss';

const Thumbnail = ({link, bgImg, title, bgColor, showTrackLogo, state}) => (
  <div className={container}>
    <Link to={link} state={state}>
      <div className={`${videoTutorial} relative`}>
        <img src={bgImg} />
        <img src={PlayBtnLogo} className={playBtn} width="55" height="55" />
      </div>
    </Link>
    <div className="relative">
      {showTrackLogo && (
        <img src={CodeLogo} width="62" height="62" className={trackLogo} />
      )}
      <h3
        className={`${trackTitle} f4-ns f7 white bold lh-copy pv3`}
        style={{backgroundColor: bgColor}}
      >
        {title}
      </h3>
    </div>
  </div>
);

Thumbnail.propTypes = {
  title: PropTypes.string,
  bgColor: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  showTrackLogo: PropTypes.bool,
};

export default Thumbnail;
