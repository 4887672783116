import notification from 'core/libs/helpers/notification';

export default (answers, onSuccess) => {
  const onboarding_survey_submissions = [];
  const data = {
    onboarding_survey_submissions,
  };

  answers.forEach((answerIndex, index) => {
    let item;
    if (index === 0) {
      item = {
        question: `question_1`,
        answer: `answer_${answerIndex + 1}`,
      };
    } else {
      const prevItem = onboarding_survey_submissions[index - 1];
      item = {
        question: `${prevItem.question}_${index + 1}`,
        answer: `${prevItem.answer}_${answerIndex + 1}`,
      };
    }
    onboarding_survey_submissions.push(item);
  });

  return {
    types: [
      'SAVE_SURVEY_REQUEST',
      'SAVE_SURVEY_SUCCESS',
      'SAVE_SURVEY_FAILURE',
    ],
    config: {
      method: 'POST',
      url: `profile/onboarding-survey/`,
      data,
    },

    callback: (success, result) => {
      if (success) {
        onSuccess();
      } else if (result.response && result.response.status >= 400) {
        const errors = Object.values(result.response.data);
        notification({
          message: errors,
        });
      }
    },
  };
};
