import React from 'react';
import PropTypes from 'prop-types';
import {compose, withHandlers, withStateHandlers} from 'recompose';
import {FormattedMessage} from 'react-intl';
import cx from 'classnames';
import NewDesignButton from 'modules/common/NewDesignButton';
import Answer from './Answer';

const Question = ({question, answers, onChoose, chosen, nextQuestion}) => {
  return (
    <div>
      <h3 className="mb4">{question}</h3>
      {answers.map((item, index) => {
        let answer;
        if (typeof item === 'string') {
          answer = {
            answer: item,
          };
        } else {
          answer = item;
        }
        return (
          <Answer
            key={answer.answer}
            index={index}
            {...answer}
            active={index === chosen}
            onChoose={onChoose}
          />
        );
      })}
      <div className="tl mt4">
        <NewDesignButton
          className={cx('pv2 ph4 white br2', {
            'bg-green-light hover-bg-green': chosen !== null,
            'bg-moon-gray': chosen === null,
          })}
          colored
          active={chosen !== null}
          onClick={nextQuestion}
        >
          <span className="dib pv1">
            <FormattedMessage id="next" />
          </span>
        </NewDesignButton>
      </div>
    </div>
  );
};

Question.propTypes = {
  question: PropTypes.string.isRequired,
  answers: PropTypes.array.isRequired,
  onChoose: PropTypes.func.isRequired,
  chosen: PropTypes.number,
  nextQuestion: PropTypes.func.isRequired,
};

const enhance = compose(
  withStateHandlers(
    {chosen: null},
    {
      onChoose: () => (chosen) => ({
        chosen,
      }),
    },
  ),
  withHandlers({
    nextQuestion: ({onValidate, chosen}) => () => {
      if (chosen !== null) {
        onValidate(chosen);
      }
    },
  }),
);

export default enhance(Question);
