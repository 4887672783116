import compose from 'core/libs/helpers/compose.js';
import spinnerWhileLoading from 'core/libs/hoc/spinnerWhileLoading';
import TrackTime from 'modules/common/TrackTime';
import React from 'react';
import {Helmet} from 'react-helmet';
import {useIntl} from 'react-intl';
import {connect} from 'react-redux';
import FullNameIllustration from 'theme/assets/images/onboarding-fullname.svg';
import SideScreen from '../components/SideScreen';

const UserFullName = (props) => {
  const intl = useIntl();
  const {formatMessage} = intl;

  return (
    <div className="bg-white tc cf">
      <Helmet title={formatMessage({id: 'helmet_post_registration'})} />
      <TrackTime message="Track time of send first and last name" />
      <SideScreen
        isUserFullNamePage
        icon={FullNameIllustration}
        description="onboarding_welcome"
        bgColor="#6C85EC"
      />
    </div>
  );
};

const enhance = compose(
  connect(({user}) => ({
    isProfileFetched: user.profile.fetched,
  })),
  spinnerWhileLoading(({isProfileFetched}) => !isProfileFetched),
);

export default enhance(UserFullName);
