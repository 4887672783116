/* eslint-disable jsx-a11y/anchor-is-valid */
import cx from 'classnames';
import {navigate} from 'gatsby';
import Link from 'modules/common/Link';
import {updateUserProfile} from 'modules/user/actions/profile';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage} from 'react-intl';
import {activeBar, bar, firstBar, grayBar, lastBar} from '../styles.scss';

const OnBoardingHero = (props) => {
  const handleClick = () => {
    const {dispatch} = props;
    dispatch(
      updateUserProfile({hide_recommended_tracks: false}, {hasNotif: false}),
    );
    // eslint-disable-next-line no-unused-expressions
    typeof window !== 'undefined' &&
      window.localStorage &&
      window.localStorage.setItem('hide_onboarding_hint', false);
    navigate('/tracks');
  };
  const {title, step, last, cy} = props;

  return (
    <div
      className={cx('pv4 ph4-ns ph3', {
        'flex items-center justify-between': !last,
      })}
    >
      {!last && (
        <h2 className="f5-ns f6-m f6" data-cy={cy}>
          <FormattedMessage id={title} />
        </h2>
      )}
      {!last ? (
        <div className="flex items-center justify-left">
          {[0, 1, 2].map((item, index) => (
            <div
              key={index}
              className={cx(bar, {
                [activeBar]: step >= item,
                [grayBar]: step < item,
                [firstBar]: item === 0,
                [lastBar]: item === 2,
              })}
            />
          ))}
        </div>
      ) : (
        <div className="tl">
          <Link
            onClick={handleClick}
            className="pointer dim dib mt3 mt0-ns ph4-ns pa2 bg-near-white ba b--black-10 br2 f6 black-80 lh-copy"
            data-cy="skip-this-step"
          >
            <FormattedMessage id="skip_step" />
          </Link>
        </div>
      )}
    </div>
  );
};

OnBoardingHero.propTypes = {
  title: PropTypes.string,
  step: PropTypes.number,
  last: PropTypes.bool,
  cy: PropTypes.string,
  dispatch: PropTypes.func,
};

export default OnBoardingHero;
