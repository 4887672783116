import React from 'react';
import PropTypes from 'prop-types';
import {compose, withHandlers} from 'recompose';
import cx from 'classnames';
import {customPv} from '../../styles.scss';

const Answer = ({answer, onClick, active}) => {
  return (
    <button
      aria-label="answer"
      type="button"
      onClick={onClick}
      className={cx(
        'w-100 pv3 ph2 mb3 bg-near-white b br2 pointer ba f5 outline-0',
        customPv,
        {
          'b--green green': active,
          'b--transparent gray': !active,
        },
      )}
      data-cy="select-objective"
    >
      <div className="pv2">{answer}</div>
    </button>
  );
};

Answer.defaultProps = {
  active: false,
};

Answer.propTypes = {
  answer: PropTypes.string.isRequired,
  active: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

const enhance = compose(
  withHandlers({
    onClick: ({onChoose, index}) => () => {
      onChoose(index);
    },
  }),
);

export default enhance(Answer);
